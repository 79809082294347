<template>
  <div class="resetForm">
    <reset-password-form />
  </div>
</template>

<script>
const ResetPasswordForm = () => import("./components/ResetPasswordForm");
export default {
  name: "ResetLogin",
  components: {
    ResetPasswordForm
  }
};
</script>

<style lang="scss" scoped>
.resetForm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
